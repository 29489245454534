@mixin fade-in($waitTime) {
    animation: 
        wait #{$waitTime},
        fade-in 800ms #{$waitTime};
}

@keyframes wait {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}