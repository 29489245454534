.arrow-container {
  position: fixed;
  left: 50%;
  bottom: 3%;
}

.arrow {
  position: relative;

  /**
   * Dark Arrow Down
   */
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  @include drop-shadow-light(#999);
  /*    border-style: solid;
   border-color: black;
   border-width: 0px 10px 10px 0px;
   border-radius: 2px; */
  /*    transition: border-width 150ms ease-in-out; */
}

.arrow-l,
.arrow-r {
  box-sizing: content-box;
  position: absolute;
  height: 50px;
  width: 12px;
  background-color: rgba(255, 252, 246, 1);
  border-radius: 8px;
  left: 20px;
}

.arrow-l {
  transform: rotate(135deg) translateX(20px);
}

.arrow-r {
  transform: rotate(45deg) translateX(20px);
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: translate(-50%, -30px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.72, 0.48);
  }
  60% {
    transform: translate(-50%, -15px);
    animation-timing-function: cubic-bezier(0.52, 0.01, 0.74, 0.07);
  }
}
