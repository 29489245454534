@import "grid12";
@import "variables";
@import "animations";
@import "bouncing-arrow";

html {
  // set this to normalize rem unit
  font-size: 10px;
  background-color: $background-primary;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Lora", serif;
  font-size: 2rem;
  line-height: 3rem;
  color: $font-grey-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

h1 {
  font-family: "Spectral SC", serif;
  font-weight: 200;
  font-size: 6.4rem;
  color: $font-white;
  line-height: 6.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h2 {
  font-family: "Spectral SC", serif;
  color: $font-black;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 500;
}
h3 {
  font-family: "Spectral SC", serif;
  color: $font-black;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 400;
  margin-bottom: 0;
}
h4 {
  font-family: "Spectral SC", serif;
  color: $font-black;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 400;
}
h5 {
  font-family: "Spectral SC", serif;
  color: $font-black;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

span {
  &.logo {
    font-weight: 400;
    font-size: 2.4rem;
    font-family: "Spectral SC", serif;
    color: $font-white;
    margin-right: 0.3rem;
  }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  transition: 300ms all;

  &.link--social {
    color: $font-grey;
    font-size: 2rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    &:hover {
      color: $font-white;
    }
  }
  &.colored {
    color: #0077ff;
    &:hover {
      color: #0057bb;
    }
  }
}

img {
  max-width: 100%;
}

video {
  max-width: 100%;
}
.video-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 1.4rem;
  right: 1.4rem;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 1rem 2rem $background-secondary;
}

hr {
  opacity: 0.3;
  overflow: visible; /* For IE */
  padding: 0;
  border: none;
  border-top: medium double $font-black;
  color: $font-black;
  text-align: center;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}
hr:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -1.8rem;
  font-size: 3rem;
  padding: 0 0rem;
  background: $background-secondary;
}

.modal-bg {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($background-primary, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fg {
  width: 60rem;
  padding: 2rem 4rem;
  background-color: $background-secondary;
}

.navbar {
  z-index: 10;
  width: 100%;
  height: 7rem;
  position: fixed;
  color: $font-grey-2;
  background: linear-gradient(
    to left,
    $background-secondary,
    rgba(255, 255, 255, 0),
    $background-secondary
  );
  font-size: 1.7rem;
  transition: 300ms all;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;
  @include fade-in("1300ms");
  .navbar-controls {
    display: none;
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    .arrow-down svg,
    .arrow-up svg {
      width: 4rem;
      height: 4rem;
    }
  }
  h3 {
    color: $font-black;
    margin: 0;
    font-size: 2rem;
    line-height: 4rem;
  }
  * {
    display: inline-block;
  }
  .left {
    position: absolute;
    left: 2.5rem;
    top: 1.5rem;
    a {
      margin-right: 2rem;
      &:hover {
        color: $font-black;
      }
    }
    .left-bordered {
      border-left: 1px solid rgba($font-black, 0.1);
      padding-left: 2rem;
    }
  }
  .right {
    position: absolute;
    right: 0;
    top: 0;
    a.link--social {
      transform: translateY(0.6rem);
      color: $font-black;
    }
    .button {
      margin-top: 0.9rem;
      margin-left: 1.6rem;
      margin-right: 2.5rem;
      background-color: rgba(0, 0, 0, 0);
      @include box-shadow(rgba(0, 0, 0, 0.2));
      &:hover {
        @include box-shadow(rgba(0, 0, 0, 0.1));
      }
    }
    .button--wallet {
      font-family: "Lora", serif;
      color: $font-grey;
      display: inline-block;
      margin-right: 2.5rem;
      &:hover {
        background-image: none;
      }
      i {
        display: inline;
      }
      img {
        // hack to make icon sit right
        margin-bottom: -0.5rem;
        display: inline;
      }
    }
  }
  &.secondary {
    background-color: $background-secondary;
    color: $font-grey-2;
    @include box-shadow(rgba(0, 0, 0, 0.1));
    .button {
      background-color: $background-white;
      @include box-shadow(rgba(0, 0, 0, 0.1));
      &:hover {
        @include box-shadow(rgba(0, 0, 0, 0.05));
      }
    }
    .button--wallet {
      color: $font-grey-2;
    }
    h3 {
      color: $font-black;
    }
    a,
    a.link--social {
      color: $font-grey-2;
      &:hover {
        color: $font-black;
      }
    }
  }
}
@media only screen and (max-width: $width-xs) {
  .navbar {
    height: 5rem;
    text-align: center;
    background-color: rgba($background-secondary, 0.9);
    a {
      margin-bottom: 2rem;
      &.logo {
        margin-top: 0.6rem;
      }
    }
    a:not(.link--social) {
      display: block;
    }
    .left {
      position: static;
      a:not(.logo) {
        display: none;
      }
      .left-bordered {
        border-left: none;
        padding-left: 0;
      }
    }
    .right {
      display: none;
      position: static;
    }
    .navbar-controls {
      display: block;
      .arrow-down {
        display: block;
      }
      .arrow-up {
        display: none;
      }
    }
    &.open {
      height: auto;
      .left {
        a:not(.logo) {
          display: block;
        }
      }
      .right {
        display: block;
        .button {
          margin-right: 2rem;
        }
        .wallet-adapter-button,
        .wallet-adapter-dropdown {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        .button--wallet {
          margin-bottom: 4rem;
        }
      }
      .navbar-controls {
        display: block;
        .arrow-down {
          display: none;
        }
        .arrow-up {
          display: block;
        }
      }
    }
  }
}

.foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url("/img/scenes/pillar_valley_fg.png") no-repeat;
  background-position: center;
  background-size: cover;
}
.section--hero {
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("/img/scenes/pillar_valley_bg.png") no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-height: 120vh;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  text-align: center;
  p {
    opacity: 0.999;
    line-height: 4rem;
    font-size: 2.6rem;
    max-width: 43rem;
    text-shadow: 0.1rem 0.2rem 0.5rem rgba(255, 255, 255, 0.6);
    color: $font-black;
    margin-top: 0;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 3s;
    @include fade-in("800ms");
    span.logo {
      font-size: 2.8rem;
      color: $font-black;
      text-decoration: solid underline $gold 0.4rem;
      text-underline-offset: 0.3rem;
    }
  }
  img {
    margin-top: calc(50vh - 25rem);
    width: 34rem;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 3s;
    @include fade-in("300ms");
    @include drop-shadow(#222);
    @media only screen and (max-width: $width-sm) {
      margin-top: calc(42vh - 25rem);
    }
  }
}
.divider {
  background-color: $background-secondary;
}
.section {
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: $background-secondary;
  &--about {
    &--top {
      padding-bottom: 0;
    }
    &--bottom {
      padding-top: 2rem;
    }
    .card {
      width: 100%;
      padding: 2rem 4rem;
      background-color: $background-white;
      @include box-shadow($shadow-color);
    }
  }
  &--faq {
    position: relative;
    overflow: hidden;
    background: linear-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      url("/img/scenes/moon_monkey_cathedral-blur.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    p {
      color: $font-black;
    }
    img {
      // margin: 6rem auto;
      display: block;
      max-width: 100rem;
      width: 100%;
      position: absolute;
      top: calc(50% - 50rem);
      right: calc(50% - 90rem);
    }
    .card {
      position: relative;
      background-color: $background-primary;
      width: 100%;
      border-left: 3rem darken($background-primary, 3%) solid;
      a {
        &.colored {
          color: #85d7ff;
          &:hover {
            color: #009eeb;
          }
        }
      }
      h4 {
        color: $font-white;
      }
      p {
        color: $font-grey;
      }
      .number {
        position: absolute;
        top: calc(50% - 1.5rem);
        left: -2rem;
        color: $font-grey-2;
        font-family: "Spectral SC", serif;
        font-size: 2rem;
      }
      .content-chunk {
        padding: 0.2rem 3rem 1.6rem 3.5rem;
        h4 {
          line-height: 3rem;
          margin-bottom: 0;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }
      &-wrapper {
        @include box-shadow(rgba(0, 0, 0, 0.2));
        margin-bottom: 2rem;
        &--special {
          border: 0.2rem $gold solid;
          .card {
            border-left: 3rem darken($background-secondary, 1%) solid;
          }
          .number {
            color: darken($gold, 3%);
            font-weight: 700;
          }
        }
        &--two-digit {
          .card {
            border-left: 3.8rem $dark-snow solid;
          }
          .number {
            left: -3rem;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &--roadmap {
    img {
      width: 100%;
    }
  }
  &--mint {
    img {
      width: 100%;
      max-width: 14rem;
    }
  }
  &--races {
    .content-chunk {
      padding: 0 3rem 0;
    }
    .selected-race {
      margin-top: 2.6rem;
      width: 50rem;
    }
    .race-avatars {
      margin-top: 1rem;
      img {
        display: inline;
        width: 5rem;
        margin-right: 1rem;
        filter: grayscale(1);
        @include box-shadow($shadow-color);
      }
    }
  }

  &--community {
    color: $font-black;
    background: linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      ),
      url("/img/scenes/rice_paddy-small.jpg") no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--footer {
    font-size: 1.6rem;
    color: $font-grey;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $background-primary;

    h3 {
      color: $font-white;
      margin: 1.6rem 0;
      line-height: 1rem;
    }
    .copyright {
      margin-top: 5rem;
      color: $font-grey-2;
    }
    .navigation {
      margin-bottom: 4rem;
      a {
        display: block;
        &:hover {
          color: $font-white;
        }
      }
    }
  }
}
@media only screen and (max-width: $width-xs) {
  .section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: $width-sm) {
  .col-sm-hidden {
    display: none;
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 132rem;
  padding: 2rem 4rem;
}
.container-row {
  margin-bottom: 20rem;
}
@media only screen and (max-width: $width-xs) {
  .content {
    padding: 1rem 1rem;
  }
}

.crest {
  display: block;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  @include drop-shadow(#777);
}

// carousel
$img-w: 3236px;
$img-h: 1284px;
$shrink: 2;
$ratio: 1 / $shrink;
$speed: 50s;

.slider {
  background-color: $background-secondary;
  width: 100%;
  top: calc(50% - #{$img-h * $ratio / 2});
  overflow: hidden;
  .slider-row {
    width: 100%;
    height: $img-h * $ratio;
    background-image: url("./img/crests/crest_carousel.png");
    background-size: $img-w * $ratio $img-h * $ratio;
    animation: slide $speed linear infinite;
    @include drop-shadow(#999);
  }
}
@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -$img-w * $ratio;
  }
}

@media only screen and (max-width: $width-xs) {
  $shrink: 3;
  $ratio: 1 / $shrink;

  .slider {
    top: calc(50% - #{$img-h * $ratio / 2});
    .slider-row {
      height: $img-h * $ratio;
      background-size: $img-w * $ratio $img-h * $ratio;
      animation: slide $speed linear infinite;
    }
  }

  @keyframes slide {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: -$img-w * $ratio;
    }
  }
}

.profile-card {
  margin-top: 2rem;
  background-color: $background-white;
  width: 100%;
  @include box-shadow($shadow-color);
  img {
    width: 100%;
  }
  .portrait-lucas {
    width: 100%;
    height: 25rem;
    background: url("/img/portraits/lucas.png") no-repeat;
    background-position: top;
    background-size: cover;
  }
  .portrait-saqif {
    width: 100%;
    height: 25rem;
    background: url("/img/portraits/saqif.png") no-repeat;
    background-position: top;
    background-size: cover;
  }
  .portrait-michael {
    width: 100%;
    height: 25rem;
    background: url("/img/portraits/michael.png") no-repeat;
    background-position: top;
    background-size: cover;
  }
  .content-chunk {
    padding: 1rem 4.5rem 3.5rem;
    h4 {
      line-height: 3rem;
      span.title {
        color: $font-grey-2;
        text-transform: lowercase;
        font-weight: 200;
      }
    }
    a {
      &.link--social {
        color: $font-grey-2;
        margin-left: 0;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }
}
.profile-card-small {
  margin-top: 2rem;
  background-color: $background-white;
  width: 100%;
  @include box-shadow($shadow-color);
  .content-chunk {
    padding: 0.2rem 3.2rem 0.3rem;
    h4 {
      font-size: 1.7rem;
      line-height: 2.4rem;
      span.title {
        color: $font-grey-2;
        text-transform: lowercase;
        font-weight: 200;
      }
    }
  }
}
@media only screen and (max-width: $width-xs) {
  .profile-card {
    margin-top: 1rem;
    .content-chunk {
      padding: 1rem 4rem 3rem;
    }
  }
}
@media only screen and (max-width: $width-sm) {
  .portrait-lucas,
  .portrait-saqif,
  .portrait-michael {
    display: none;
  }
  .profile-card-small {
    img {
      display: none;
    }
  }
}

.button {
  background: #fff;
  display: inline-block;
  height: auto;
  width: auto;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  transition: 300ms all;
  @include box-shadow(rgba(229, 232, 235, 0.5));
  &:hover {
    transform: translateY(0.2rem);
    transition: 300ms all;
    @include box-shadow(rgba(229, 232, 235, 0.1));
  }
}

.mint-container.mint-container {
  background: rgba(255, 252, 246, 0.53);
  padding: 16px;
}

.whitepaper {
  h3 {
    margin-top: 6rem;
  }
  h4 {
    margin-top: 5rem;
  }
  h5 {
    margin-top: 5rem;
  }
}

.text {
  &--center {
    text-align: center;
  }
}

.bg-moon-monkey {
  min-height: 40rem;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url("/img/scenes/moon_monkey_cathedral-sq.png") no-repeat;
  background-position: center;
  background-size: cover;
}

.MuiSnackbar-root {
  transition: 300ms all;
  @include box-shadow(rgba(0, 0, 0, 0.4));
  &:hover {
    @include box-shadow(rgba(0, 0, 0, 0.6));
  }
}

@media (max-width: $width-sm) {
  .bg-moon-monkey {
    margin: 4rem 1.6rem;
  }
}

@media (min-width: $width-sm) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}

.v-center-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

$select-wallet-container: ".MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiPaper-rounded";

#{$select-wallet-container} .MuiDialogTitle-root {
  & h2 {
    color: white;
  }
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(117, 117, 117, 0.3)),
    url("/img/scenes/moon_monkey_cathedral-blur.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

#{$select-wallet-container} .MuiDialogContent-root {
  & .MuiList-root {
    background-color: $background-secondary;
    & .MuiListItem-root {
      box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
      & .MuiButton-root {
        color: $background-primary;
      }
    }
  }
}
